
import { logger, attachDeviceInfoToData, isCordova } from '../shared/utility';
import mobiscroll from "@mobiscroll/react";
import axiosInstance from '../components/axios';
import Cookies from 'universal-cookie';
import * as Sentry from '@sentry/browser';
import { mpoSentry } from './Sentry';

export const mpoOneSignal = {
    //https://github.com/OneSignal/OneSignal-Cordova-SDK/blob/master/www/OneSignal.js
    _initted: false,
    _init_attempts: 0,
    _app_props: null,
    _prompted: false,
    _provider: 'OneSignal',
    _OneSignal_instance: null,
    _OneSignal_appId: null,
    _OneSignal_userId: null,
    _OneSignal_pushToken: null,
    _OneSignal_isRegistered: false,
    _isCustomApp: false,
    _cookieName: 'OneSignalPush',

    Init: function (oneSignalAppId, isCustomApp, appProps = null) {
        logger('mpoOneSignal.Init');
        mpoOneSignal._init_attempts++;

        if (mpoOneSignal._initted || !oneSignalAppId || !isCordova()) {
            return;
        }

        if (oneSignalAppId && window.plugins && window.plugins.OneSignal) {
            try {
                mpoOneSignal._initted = true;
                mpoOneSignal._app_props = appProps;
                mpoOneSignal._OneSignal_appId = oneSignalAppId;
                mpoOneSignal._isCustomApp = isCustomApp;

                const cookies = new Cookies();
                const prompted = cookies.get(mpoOneSignal._cookieName+"_prompted");
                if (prompted && prompted === 'auto') {
                    mpoOneSignal._prompted = true;
                }

                //V2
                /*
                mpoOneSignal._OneSignal_instance = window.plugins.OneSignal.startInit(oneSignalAppId)
                    .handleNotificationOpened(mpoOneSignal.notificationOpenedCallback)
                    //.handleInAppMessageClicked(mpoOneSignal.inAppMessageClickedCallback)
                    //.inFocusDisplaying(window.plugins.OneSignal.OSInFocusDisplayOption.InAppAlert)
                    .iOSSettings({kOSSettingsKeyAutoPrompt: false})
                    .endInit();
                 */
                //V3
                mpoOneSignal._OneSignal_instance = window.plugins.OneSignal.setAppId(oneSignalAppId);
                window.plugins.OneSignal.setNotificationOpenedHandler(mpoOneSignal.notificationOpenedCallback);
                //window.plugins.OneSignal.setInAppMessageClickHandler(mpoOneSignal.inAppMessageClickedCallback);

                //if (ConfigGlobal.Debug()) {
                    //window.plugins.OneSignal.setLogLevel(4,0);
                //}

                if (mobiscroll.platform.name === 'ios') {
                    window.plugins.OneSignal.addPermissionObserver(mpoOneSignal.permissionObserver);
                }

                //window.plugins.OneSignal.getIds(mpoOneSignal.registerCallback);
                mpoOneSignal.getPermissionStatus();

                //todo:
                //window.plugins.OneSignal.syncHashedEmail(userEmail);

            } catch(error) {
                mpoSentry.captureException(error);
            }
        } else if (mpoOneSignal._init_attempts < 3) {
            // retry in 5 seconds
            setTimeout(mpoOneSignal.Init, 5000, oneSignalAppId, isCustomApp, appProps);
        }
    },

    SetProps: function (appProps) {
        if (appProps !== null) {
            mpoOneSignal._app_props = appProps;
        }
    },

    IsRegistered: function() {
        logger('mpoOneSignal.IsRegistered');
        //return mpoOneSignal._OneSignal_userId && mpoOneSignal._OneSignal_pushToken;
        return mpoOneSignal._OneSignal_isRegistered;
    },

    GetPnData: function() {
        logger('mpoOneSignal.GetPnData');
        return {
            provider: mpoOneSignal._provider,
            appId: mpoOneSignal._OneSignal_appId,
            userId: mpoOneSignal._OneSignal_userId,
            pushToken: mpoOneSignal._OneSignal_pushToken
        };
    },

    //callback fired when a notification has been opened
    notificationOpenedCallback: function(notif) {
        logger('mpoOneSignal.notificationOpenedCallback');
        logger(notif);
        //mobiscroll.toast({message: "OneSignal: "+JSON.stringify(notif), color: 'info'});

        var pndata = mpoOneSignal.GetPnData();
        pndata['notif'] = notif;

        var data = {
            RequestAction: 'Push',
            sub_action: 'track_open',
            pn_data: pndata
        };

        axiosInstance.post(null, data)
        .then(response => {
            //mobiscroll.notification.dismiss();
            if (response.data.ResponseCode === "SUCCESS") {
                mpoOneSignal._track_open_success(response.data);
            } else {
                mpoOneSignal._track_open_error(response.data);
            }

            // https://documentation.onesignal.com/docs/cordova-sdk#osnotificationopenedresult
            let payload = null;
            let pathName = null;
            let queryString = "";
            let payloadAction = null;
            if (notif.hasOwnProperty('notification') && notif.notification.hasOwnProperty('payload')) {
                //notif.notification.isAppInFocus
                payload = notif.notification.payload;
                if (payload.hasOwnProperty('additionalData') && payload.additionalData.hasOwnProperty('path') && payload.additionalData.path !== "") {
                    pathName = payload.additionalData.path;
                    if (payload.additionalData.hasOwnProperty('query') && payload.additionalData.query !== "") {
                        queryString = payload.additionalData.query;
                    }
                }
                if (payload.hasOwnProperty('additionalData') && payload.additionalData.hasOwnProperty('action') && payload.additionalData.action !== "") {
                    payloadAction = payload.additionalData.action;
                }
            }

            if (mpoOneSignal._app_props !== undefined && mpoOneSignal._app_props !== null && mpoOneSignal._app_props.hasOwnProperty('history') && pathName !== null) {
                // redirect to somewhere in the application, e.g. to a specific menu.
                //  params can also include additional data such as a promo code or table number
                let location = {
                    pathname: pathName,
                    search: queryString,
                    state: { fromNotification: true }
                }
                if (mpoOneSignal._app_props.location.pathname === pathName) {
                    mpoOneSignal._app_props.history.replace(location);
                } else {
                    mpoOneSignal._app_props.history.push(location);
                }
            }

            if (payload !== null && payloadAction !== null) {
                // trigger an in-app notification from tapping a push notification
                if (payloadAction === "inapp_prompt") {
                    let prompt = null;
                    if (payload.hasOwnProperty('additionalData') && payload.additionalData.hasOwnProperty('prompt') && payload.additionalData.prompt !== "") {
                        // e.g. rating_prompt
                        prompt = payload.additionalData.prompt;
                    }
                    if (prompt !== null) {
                        mpoOneSignal.AddInAppMessageTrigger(prompt, "true");
                    }
                }
            }

        })
        .catch(error => {
            //mobiscroll.notification.dismiss();
            mobiscroll.toast({message: 'OneSignal Track Open Exception: '+error, color: 'danger'});
            mpoSentry.captureException(error);
        });

    },

    _track_open_success: function(data) {
        logger('mpoOneSignal._track_open_success() called');
        logger(data);
        //mobiscroll.toast({message: 'OneSignal Tracked Open', color: 'success'});
    },

    _track_open_error: function(data) {
        logger('mpoOneSignal._track_open_error() called');
        logger(data);
        //mobiscroll.toast({message: 'OneSignal Track Open Error: '+data.Response[0], color: 'danger'});
        mpoSentry.captureMessage(data.Response[0], Sentry.Severity.Warning);
    },

    registerCallback: function(pndata) {
        logger('mpoOneSignal.registerCallback');
        logger(pndata);

        if (pndata && pndata.userId && pndata.pushToken) {
            mpoOneSignal._OneSignal_userId = pndata.userId;
            mpoOneSignal._OneSignal_pushToken = pndata.pushToken;
            //mpoOneSignal._OneSignal_isRegistered = mpoOneSignal._OneSignal_userId && mpoOneSignal._OneSignal_pushToken;
            mpoOneSignal.getPermissionStatus();
        }
    },

    _register_success: function(data) {
        logger('mpoOneSignal._register_success() called');
        logger(data);
        mpoOneSignal._get_timezone();
    },

    _register_error: function(data) {
        logger('mpoOneSignal._register_error() called');
        logger(data);
    },

    _set_prompted_cookie: function() {
        mpoOneSignal._prompted = true;
        const cookies = new Cookies();
        const maxAge = parseInt(process.env.REACT_APP_SESSION_MINS,10)*60;
        cookies.set(mpoOneSignal._cookieName+"_prompted", "auto", {maxAge: maxAge});
    },

    _get_timezone: function() {
        logger('mpoOneSignal._get_timezone() called');
        if (typeof (navigator) !== 'undefined' && 'globalization' in navigator) {
            navigator.globalization.getDatePattern(mpoOneSignal._get_timezone_success, mpoOneSignal._get_timezone_error);
        }
    },

    _get_timezone_success: function(data) {
        logger('mpoOneSignal._get_timezone_success() called');
        logger(data);

        var pndata = mpoOneSignal.GetPnData();
        pndata['timezone'] = data;

        var sdata = {
            RequestAction: 'Push',
            sub_action: 'timezone',
            pn_data: pndata
        };

        axiosInstance.post(null, sdata)
        .then(response => {
            mobiscroll.notification.dismiss();
            if (response.data.ResponseCode === "SUCCESS") {
                mpoOneSignal._update_timezone_success(response.data);
                //mobiscroll.toast({message: 'Timezone registered', color: 'success'});
            } else {
                mpoOneSignal._update_timezone_error(response.data);
                //mobiscroll.toast({message: response.data.Response[0], color: 'danger'});
                mpoSentry.captureMessage(response.data.Response[0], Sentry.Severity.Warning);
            }
        })
        .catch(error => {
            //mobiscroll.notification.dismiss();
            //mobiscroll.toast({message: 'Error getting Timezone', color: 'danger'});
            mpoSentry.captureException(error);
        });

    },

    _get_timezone_error: function(data) {
        logger('mpoOneSignal._get_timezone_error() called');
        logger(data);
    },

    _update_timezone_success: function(data) {
        logger('mpoOneSignal._update_timezone_success() called');
        logger(data);
    },

    _update_timezone_error: function(data) {
        logger('mpoOneSignal._update_timezone_error() called');
        logger(data);
    },

    getPermissionStatus: function() {
        logger('mpoOneSignal.getPermissionStatus() called');
        if (mpoOneSignal._initted) {
            //V2
            /*
            window.plugins.OneSignal.getPermissionSubscriptionState(function (status) {
                mpoOneSignal.checkPermissionStatusV2(status);
            });
             */
            //V3
            window.plugins.OneSignal.getDeviceState(function(deviceState) {
                logger('OneSignal getDeviceState: ' + JSON.stringify(deviceState));
                mpoOneSignal.checkPermissionStatusV3(deviceState);
            });
        }
    },

    checkPermissionStatusV3: function(deviceState) {
        logger('mpoOneSignal.checkPermissionStatusV3() called');
        logger(deviceState);

        // https://documentation.onesignal.com/docs/cordova-30-api-reference#other-functions
        if (deviceState.hasNotificationPermission) {

            logger('checkPermissionStatusV3: hasNotificationPermission');
            mpoOneSignal._OneSignal_userId = deviceState.userId;
            mpoOneSignal._OneSignal_pushToken = deviceState.pushToken;
            mpoOneSignal._OneSignal_isRegistered = mpoOneSignal._OneSignal_userId && mpoOneSignal._OneSignal_pushToken && deviceState.isSubscribed;

            if (deviceState.isSubscribed) {
                //mobiscroll.toast({message: 'Subscribed', color: 'info'});
                var data = {
                    RequestAction: 'Push',
                    sub_action: 'register',
                    pn_data: mpoOneSignal.GetPnData()
                };

                attachDeviceInfoToData(data);

                axiosInstance.post(null, data)
                    .then(response => {
                        mobiscroll.notification.dismiss();
                        if (response.data.ResponseCode === "SUCCESS") {
                            mpoOneSignal._register_success(response.data);
                            //mobiscroll.toast({message: 'Successfully registered', color: 'success'});
                        } else {
                            mpoOneSignal._register_error(response.data);
                            //mobiscroll.toast({message: response.data.Response[0], color: 'danger'});
                            mpoSentry.captureMessage(response.data.Response[0], Sentry.Severity.Warning);
                        }
                    })
                    .catch(error => {
                        mobiscroll.notification.dismiss();
                        mobiscroll.toast({message: 'Error registering', color: 'danger'});
                        mpoSentry.captureException(error);
                    });
            } else {
                mpoOneSignal._OneSignal_isRegistered = false;
            }
        }

    },

    checkPermissionStatusV2: function(status) {
        logger('mpoOneSignal.checkPermissionStatusV2() called');
        logger(status);

        //https://documentation.onesignal.com/docs/sdk-reference#getpermissionsubscriptionstate-method
        // permissionStatus.hasPrompted; // Bool
        // permissionStatus.status; // iOS only: Integer: 0 = Not Determined, 1 = Denied, 2 = Authorized, 3 = Provisional?
        // permissionStatus.state; //Android only: Integer: 1 = Authorized, 2 = Denied
        //mobiscroll.toast({message: 'checkPermissionStatus: ' + status.permissionStatus.status + '; hasPrompted ' + (status.permissionStatus.hasPrompted ? 'true' : 'false'), color: 'info'});
        if (((mobiscroll.platform.name === 'ios' && parseInt(status.permissionStatus.status, 10) === 2)) ||
            ((mobiscroll.platform.name === 'android' && parseInt(status.permissionStatus.status, 10) === 1))) {
            logger('checkPermissionStatusV2: Authorized');
            mpoOneSignal._OneSignal_userId = status.subscriptionStatus.userId;
            mpoOneSignal._OneSignal_pushToken = status.subscriptionStatus.pushToken;
            mpoOneSignal._OneSignal_isRegistered = mpoOneSignal._OneSignal_userId && mpoOneSignal._OneSignal_pushToken;
            if (status.subscriptionStatus.subscribed) {
                //mobiscroll.toast({message: 'Subscribed', color: 'info'});
                var data = {
                    RequestAction: 'Push',
                    sub_action: 'register',
                    pn_data: mpoOneSignal.GetPnData()
                };

                attachDeviceInfoToData(data);

                axiosInstance.post(null, data)
                    .then(response => {
                        mobiscroll.notification.dismiss();
                        if (response.data.ResponseCode === "SUCCESS") {
                            mpoOneSignal._register_success(response.data);
                            //mobiscroll.toast({message: 'Successfully registered', color: 'success'});
                        } else {
                            mpoOneSignal._register_error(response.data);
                            //mobiscroll.toast({message: response.data.Response[0], color: 'danger'});
                            mpoSentry.captureMessage(response.data.Response[0], Sentry.Severity.Warning);
                        }
                    })
                    .catch(error => {
                        mobiscroll.notification.dismiss();
                        mobiscroll.toast({message: 'Error registering', color: 'danger'});
                        mpoSentry.captureException(error);
                    });
            } else {
                mpoOneSignal._OneSignal_isRegistered = false;
            }
        }

    },

    // https://documentation.onesignal.com/docs/sdk-reference#addsubscriptionobserver-method
    permissionObserver: function(state) {
        logger('mpoOneSignal.permissionObserver() called');
        logger(state);
        if (state.from.status !== state.to.status) {
            mpoOneSignal.getPermissionStatus();
        }
    },

    inAppMessageClickedCallback: function(action) {
        logger('mpoOneSignal.inAppMessageClickedCallback() called');
        logger(action);
        if (mpoOneSignal._initted) {
            // let firstClick = action.first_click;
            // let closesMessage = action.closes_message;
            // let clickUrl = action.click_url;
            // let clickName = action.click_name;
        }
    },

    PauseInAppMessages: function(pause) {
        logger('mpoOneSignal.PauseInAppMessages() called');
        logger(pause);
        // To pause, set true.
        // To resume, set false.
        if (mpoOneSignal._initted) {
            window.plugins.OneSignal.pauseInAppMessages(pause);
        }
    },

    AddInAppMessageTrigger: function(key, value) {
        logger('mpoOneSignal.AddInAppMessageTrigger() called');
        logger(key+'='+value);
        if (mpoOneSignal._initted) {
            window.plugins.OneSignal.addTrigger(key, value);
        }
    },

    RemoveInAppMessageTrigger: function(key) {
        logger('mpoOneSignal.RemoveInAppMessageTrigger() called');
        logger(key);
        if (mpoOneSignal._initted) {
            window.plugins.OneSignal.removeTriggerForKey(key);
        }
    },

    TriggerInAppIosPrompt: function() {
        logger('mpoOneSignal.TriggerInAppIosPrompt() called');
        if (mpoOneSignal._initted && mobiscroll.platform.name === 'ios' && !mpoOneSignal._prompted) {
            window.plugins.OneSignal.getPermissionSubscriptionState(function (status) {
                logger('OneSignal.getPermissionSubscriptionState');
                logger(status);
                if (!status.permissionStatus.hasPrompted) {
                    mpoOneSignal.DisplayRegisterPopup();
                }
            });
        }
    },

    DisplayRegisterPopup: function() {
        logger('mpoOneSignal.DisplayRegisterPopup() called');
        if (mpoOneSignal._initted && mobiscroll.platform.name === 'ios') {
            /*
            // pre-v3.0.32
            mobiscroll.confirm({
                title: 'Notifications',
                message: "Would you like to receive notifications about your orders and occasional discounts and offers?",
                okText: 'Allow',
                cancelText: 'Maybe Later',
                callback: function (res) {
                    if (res) {
                        mpoOneSignal.RegisterPushNotifications();
                        mpoSentry.addBreadcrumb('Push', 'Accepted push notifs', Sentry.Severity.Info);
                    } else {
                        mpoSentry.addBreadcrumb('Push', 'Declined push notifs', Sentry.Severity.Info);
                    }
                }
            });
            */
            // from V3.0.32
            mpoOneSignal.AddInAppMessageTrigger("prompt_ios", "true");
            mpoOneSignal._set_prompted_cookie();
        }
    },

    RegisterPushNotifications: function() {
        logger('mpoOneSignal.RegisterPushNotifications() called');
        if (mpoOneSignal._initted) {
            window.plugins.OneSignal.registerForPushNotifications();
        }
    },

    TriggerInAppLocationPrompt: function() {
        logger('mpoOneSignal.TriggerInAppLocationPrompt() called');
        if (mpoOneSignal._initted) {
            mpoOneSignal.AddInAppMessageTrigger("location_prompt", "true");
        }
    },

    TriggerInAppWelcomePrompt: function() {
        logger('mpoOneSignal.TriggerInAppWelcomePrompt() called');
        if (mpoOneSignal._initted) {
            mpoOneSignal.AddInAppMessageTrigger("welcome_prompt", "true");
        }
    },

    TriggerInAppPromoPrompt: function() {
        logger('mpoOneSignal.TriggerInAppPromoPrompt() called');
        if (mpoOneSignal._initted) {
            mpoOneSignal.AddInAppMessageTrigger("promo_prompt", "true");
        }
    },

    TriggerInAppStoreRatingPrompt: function() {
        logger('mpoOneSignal.TriggerInAppStoreRatingPrompt() called');
        if (mpoOneSignal._initted) {
            mpoOneSignal.AddInAppMessageTrigger("rating_prompt", "true");
        }
    }

};

